var RESERVED_SEATING_CONSTANTS = {
	SVG: 'elevent-seat-selection',
	SCREEN_OBJ: 'elevent-screen-rect',
	SCREEN_OBJ_TEXT: 'elevent-screen-rect-text',
	AVAILABLE_SEAT: 'elevent-available-seat',
	SELECTED_SEAT: 'elevent-selected-seat',
	UNAVAILABLE_SEAT: 'elevent-unavailable-seat',
	SOLD_SEAT: 'elevent-sold-seat',
	ORPHAN_SEAT: 'elevent-orphan-seat',
	TOOLTIP: 'elevent-seat-tooltip',
	SEAT_PIXEL_SIZE: 15,
};

var TOOLTIP_TYPE = {
	NONE: 0,
	INTERNAL: 1,
	CUSTOMER: 2,
};

var SEAT_ATTRIBUTE_TAG = {
	HANDICAP: 'elevent-attr-h-',
	OBSTRUCTED: 'elevent-attr-ov-',
	MULTIPLE: 'elevent-attr-m-',
	UNAVAILABLE: 'elevent-attr-u-',
	ALLOCATION: 'elevent-attr-a-',
	TICKET: 'elevent-attr-t-',
};

var globalViewer;
var DigitalVenueMap = {
	InitMap: function(mapId, elementId, styles, mapLoadedCallback, clickCallback, enterCallback, leaveCallback, isDev) {
		if(!DVM) {
			alert('Digital Venue library not loaded!');
			console.error('Digital Venue library not loaded!');
			return null;
		}
		
		if(mapId == null || elementId == null || mapLoadedCallback == null) {
			alert('mapId, elementId, and mapLoadedCallback are required!');
			console.error('mapId, elementId, and mapLoadedCallback are required!');
		}
		
		var options = {
            container: elementId,
            callbacks: {
                click: function (obj) { 
					if(clickCallback)
						clickCallback(obj.nodes[0]); 
				},
                enter: function (obj) { 
					if(enterCallback)
						enterCallback(obj.nodes[0]); 
				},
                leave: function (obj) { 
					if(leaveCallback)
						leaveCallback(obj.nodes[0]); 
				}
            }
        };
		
		var mapObj;
		DVM.loadModule("map_viewer", options).then(function (map) {
            mapObj = map;
			if(isDev)
				globalViewer = map;
			DigitalVenueMap._initTooltip(document.getElementById(elementId));
            mapObj.loadMap({ venue_id: mapId }).then(function() {
				if(styles != null)
					mapObj.setStyles(styles);
				else 
					mapObj.setStyles(DigitalVenueMap.Styles);
				mapObj.flags.fixed_aspect_ratio = false;
				if(mapLoadedCallback)
					mapLoadedCallback(mapObj);
			}, function () {
                ShowErrorAlert("Failure to load map. Please refresh the page.");
            }).catch(function (err) {
                console.error(err);
                ShowErrorAlert("Failure to load map. Please refresh the page.");
            });			
        });
	},
	testMapId: function(mapId, elementId, callback) {
		if(!DVM) {
			alert('Digital Venue library not loaded!');
			console.error('Digital Venue library not loaded!');
			return null;
		}
		
		if(mapId == null || elementId == null) {
			alert('mapId, elementId, and mapLoadedCallback are required!');
			console.error('mapId, elementId, and mapLoadedCallback are required!');
		}
		
		var options = {
            container: elementId,            
        };
		
		var mapObj;		
		DVM.loadModule("map_viewer", options).then(function (map) {
            mapObj = map;
			DigitalVenueMap._initTooltip(document.getElementById(elementId));
            mapObj.loadMap({ venue_id: mapId }).then(function() {
				callback(true);		
			}, function () {
                callback(false);	
            }).catch(function (err) {
                callback(false);	
            });			
        });
	},
	_initTooltip: function (mapElement) {
		var container = document.createElement('div');
            container.id = RESERVED_SEATING_CONSTANTS.TOOLTIP;
            container.style.display = 'none';

            var table = document.createElement('table');
            table.id = 'elevent-seat-tooltip-table';

            var sectionRow = document.createElement('tr');
            var sectionCol = document.createElement('td');
            var section = document.createElement('p');
            sectionCol.classList.add('elevent-section-tooltip-table-header');
            sectionCol.setAttribute('colspan', '2');
            section.id = 'elevent-section-tooltip-name';
            sectionCol.appendChild(section);
            sectionRow.appendChild(sectionCol);

            var headerRow = document.createElement('tr');
            var headerRowCol = document.createElement('td');
            var headerSeatCol = document.createElement('td');
            headerRowCol.classList.add('elevent-seat-tooltip-table-header');
            headerRowCol.innerHTML = 'Row';
            headerSeatCol.classList.add('elevent-seat-tooltip-table-header');
            headerSeatCol.innerHTML = 'Seat';
            headerRow.appendChild(headerRowCol);
            headerRow.appendChild(headerSeatCol);

            var dataRow = document.createElement('tr');
            var dataRowCol = document.createElement('td');
            var dataSeatCol = document.createElement('td');
            var row = document.createElement('p');
            var seat = document.createElement('p');
            dataRowCol.classList.add('elevent-seat-tooltip-table-row-seat');
            row.id = 'elevent-seat-row-name';
            dataRowCol.appendChild(row);
            dataSeatCol.classList.add('elevent-seat-tooltip-table-row-seat');
            seat.id = 'elevent-seat-seat-name';
            dataSeatCol.appendChild(seat);
            dataRow.appendChild(dataRowCol);
            dataRow.appendChild(dataSeatCol);

            var unavailableRow = document.createElement('tr');
            var unavailableCol = document.createElement('td');
            unavailableRow.id = SEAT_ATTRIBUTE_TAG.UNAVAILABLE + 'tooltip';
            unavailableCol.classList.add('elevent-seat-unavailable');
            unavailableCol.setAttribute('colspan', '2');
            unavailableCol.innerHTML = 'Unavailable';
            unavailableRow.appendChild(unavailableCol);

            var allocationRow = document.createElement('tr');
            var allocationCol = document.createElement('td');
            var allocation = document.createElement('p');
            allocationRow.id = SEAT_ATTRIBUTE_TAG.ALLOCATION + 'tooltip';
            allocationCol.classList.add('elevent-seat-allocation');
            allocationCol.setAttribute('colspan', '2');
            allocation.id = 'elevent-seat-allocation-data';
            allocationCol.appendChild(allocation);
            allocationRow.appendChild(allocationCol);

			var ticketRow = document.createElement('tr');
            var ticketCol = document.createElement('td');
            var ticket = document.createElement('p');
            ticketRow.id = SEAT_ATTRIBUTE_TAG.TICKET + 'tooltip';
            ticketCol.classList.add('elevent-seat-ticket');
            ticketCol.setAttribute('colspan', '2');
            ticket.id = 'elevent-seat-ticket-data';
            ticketCol.appendChild(ticket);
            ticketRow.appendChild(ticketCol);
			
            var handicapRow = document.createElement('tr');
            var handicapCol = document.createElement('td');
            handicapRow.id = SEAT_ATTRIBUTE_TAG.HANDICAP + 'tooltip';
            handicapCol.classList.add('elevent-seat-attribute');
            handicapCol.setAttribute('colspan', '2');
            handicapCol.innerHTML = '&bull;&nbsp;Handicap Accessible';
            handicapRow.appendChild(handicapCol);

            var obstructedRow = document.createElement('tr');
            var obstructedCol = document.createElement('td');
            obstructedRow.id = SEAT_ATTRIBUTE_TAG.OBSTRUCTED + 'tooltip';
            obstructedCol.classList.add('elevent-seat-attribute');
            obstructedCol.setAttribute('colspan', '2');
            obstructedCol.innerHTML = '&bull;&nbsp;Obstructed View';
            obstructedRow.appendChild(obstructedCol);

            table.appendChild(sectionRow);

            table.appendChild(headerRow);
            table.appendChild(dataRow);

            table.appendChild(unavailableRow);
            table.appendChild(allocationRow);
			table.appendChild(ticketRow);

            table.appendChild(handicapRow);
            table.appendChild(obstructedRow);

            container.appendChild(table);
            mapElement.parentNode.appendChild(container);            
	},
	ShowTooltip: function (seat, dbSeat, tooltipType, ticketInfo) {
		var coordinates = {
			x: seat.anchor.dom[0],
			y: seat.anchor.dom[1]
		};

		var sectionName = document.getElementById('elevent-section-tooltip-name');
		var rowName = document.getElementById('elevent-seat-row-name');
		var seatName = document.getElementById('elevent-seat-seat-name');
		var ticketData = document.getElementById('elevent-ticket-tooltip-name');
		sectionName.innerHTML = dbSeat.VenueSectionName;
		rowName.innerHTML = dbSeat.RowName;
		seatName.innerHTML = dbSeat.SeatName;
		var handicap = document.getElementById(SEAT_ATTRIBUTE_TAG.HANDICAP + 'tooltip');
		handicap.style.display = 'none';
		var obstructed = document.getElementById(SEAT_ATTRIBUTE_TAG.OBSTRUCTED + 'tooltip');
		obstructed.style.display = 'none';
		//ReservedSeating.ShowTooltipAttribute(SEAT_TYPES.HANDICAP, seat.IsHandicapAccessible);
		//ReservedSeating.ShowTooltipAttribute(SEAT_TYPES.OBSTRUCTED, seat.IsObstructedView);

		var elem;
		switch (tooltipType) {
			case TOOLTIP_TYPE.INTERNAL:
				elem = document.getElementById(SEAT_ATTRIBUTE_TAG.UNAVAILABLE + 'tooltip');
				elem.style.display = 'none';
				elem = document.getElementById(SEAT_ATTRIBUTE_TAG.ALLOCATION + 'tooltip');
				elem.style.display = 'none';
				break;
			case TOOLTIP_TYPE.CUSTOMER:
				if (dbSeat.IsSeatUnavailable) {
					elem = document.getElementById(SEAT_ATTRIBUTE_TAG.UNAVAILABLE + 'tooltip');
					elem.style.display = 'table-row';
					elem = document.getElementById(SEAT_ATTRIBUTE_TAG.ALLOCATION + 'tooltip');
					elem.style.display = 'none';
					elem = document.getElementById(SEAT_ATTRIBUTE_TAG.TICKET + 'tooltip');
					elem.style.display = 'none';
				} else {
					elem = document.getElementById(SEAT_ATTRIBUTE_TAG.UNAVAILABLE + 'tooltip');
					elem.style.display = 'none';
					elem = document.getElementById(SEAT_ATTRIBUTE_TAG.ALLOCATION + 'tooltip');
					elem.style.display = 'table-row';
					elem = document.getElementById(SEAT_ATTRIBUTE_TAG.TICKET + 'tooltip');
					elem.style.display = 'table-row';
					if(ticketInfo != null && ticketInfo.length > 0) {
						var ticket = document.getElementById('elevent-seat-ticket-data');
						ticket.innerHTML = ticketInfo;
					}
					
					//var allocation = document.getElementById('elevent-seat-allocation-data'); //TODO: Show available allocations to customer
					//allocation.innerHTML = dbSeat.PrivateAllocationString.length > 0 ? dbSeat.PrivateAllocationString : "Available";
				}
				break;
		}

		var tooltip = document.getElementById(RESERVED_SEATING_CONSTANTS.TOOLTIP);
		tooltip.style.top = parseFloat(coordinates.y + 50) + 'px';
		tooltip.style.left = parseFloat(coordinates.x + 10) + 'px';
		tooltip.style.display = 'block';
	},
	Styles:  [
            {
                seat: null,
                "section":{
                    "available":{
                        "normal":{
                            "none":{
                                "fillStyle":"#FDFDFD",
                                "strokeStyle":"#0275d8",
                                "lineWidth":0.3,
                                "cursor":"auto",
                                "text":true,
                                "textFillStyle":"#0275d8",
								"textFontFamily": "Montserrat",
								"textFontSize": "3"
                            }
                        },
                        "hover":{
                            "none":{
                                "fillStyle":"#FDFDFD",
                                "strokeStyle":"#0275d8",
                                "lineWidth":0.6,
                                "cursor":"pointer",
                                "text":true,
                                "textFillStyle":"#0275d8",
								"textFontFamily": "Montserrat",
								"textFontSize": "3"
                            }
                        }
                    },
                    "unavailable":{
                        "normal":{
                            "none":{
                                "fillStyle":"#C0C0C0",
                                "strokeStyle":"#C0C0C0",
                                "lineWidth":0.3,
                                "cursor":"auto",
                                "text":true,
                                "textFillStyle":"#ffffff",
								"textFontFamily": "Montserrat",
								"textFontSize": "3"
                            }
                        }
                    },
                    "disabled":"unavailable"
                }
            },
            {
                seat: {
                    available: {
                        normal: {
                            none: {
                                strokeStyle: '#7d7a7a',
                                fillStyle: 'transparent',
                                cursor: 'pointer',
                                lineWidth: 0.075
                            },
                        },
                        hover: {
                            none: {
                                fillStyle: '#97b2df', //'#1b66e2',// "#2cc3e0",
                                strokeStyle: '#97b2df',
                                lineWidth: 0.075,
                                cursor: "pointer"
                            },
                        }
                    },
                    unavailable: {
                        normal: {
                            none: {
                                fillStyle: "#C0C0C0",
                                cursor: "auto"
                            }
                        }
                    },
                    selected: {
                        normal: {
                            none: {
                                fillStyle: "#0047bb",
                                strokeStyle: "#1b66e2",
                                lineWidth: 0.075,
                                fillOpacity: 0.8,
                                cursor: "pointer"
                            },
                        },
                        hover: {
                            none: {
                                fillStyle: "#0047bb",
                                strokeStyle: "#1b66e2",
                                lineWidth: 0.075,
                                fillOpacity: 0.8,
                                cursor: "pointer"
                            },
                        }
                    }
                },
                "section":{
                    "available":{
                        "normal":{
                            "none":{
                                "fillStyle":"#FFFFFF",
                                "strokeStyle":"#FFFFFF",
                                "lineWidth":0.3,
                                "cursor":"auto"
                            }
                        },
                        "hover":{
                            "none":{
                                "fillStyle":"#FFFFFF",
                                "strokeStyle":"#FFFFFF",
                                "lineWidth":0.3,
                                "cursor":"auto"
                            }
                        }
                    },
                    "unavailable":{
                        "normal":{
                            "none":{
                                "fillStyle":"#F3F3F3",
                                "strokeStyle":"#F3F3F3",
                                "lineWidth":0.3,
                                "cursor":"auto"
                            }
                        }
                    },
                    "disabled":"unavailable"
                }
            }
        ]
};