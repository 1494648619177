function getAddOnDetail(addOn, parentItemId, quantity, clientId, clientName, antiForgeryToken) {
    return new Promise(function (resolve, reject) {
        if (addOn == null || addOn.length <= 0)
            reject();

        var result = new Array();
        axios({
            method: 'post',
            url: baseUrl + 'AddOn/GetAddOnDetails',
            data: {
                model: addOn
            },
            headers: {
                'RequestVerificationToken': antiForgeryToken
            }
        }).then(function (response) {
            if (response.data == null || response.data == '')
                return null;

            if (addOn.ItemTypeId === 1) {
                //ticket
                for (var i = 0; i < quantity; i++) {
                    var eventDetail = JSON.parse(JSON.stringify(response.data));
                    var ticketAddOn = {
                        EventId: eventDetail.EventId,
                        EventName: eventDetail.EventName,
                        EventTypeId: eventDetail.EventTypeId,
                        EventScheduleTypeId: eventDetail.EventScheduleTypeId,
                        LengthInMinutes: eventDetail.LengthInMinutes,
                        ClientId: clientId,
                        ClientName: clientName,
                        EventScheduleId: eventDetail.EventScheduleId,
                        StartDateTime: GetDateTimeFromParts(eventDetail.StartDateTimeParts),
                        StartDateTimeParts: eventDetail.StartDateTimeParts,
                        EndDateTime: eventDetail.EndDateTime != null ? eventDetail.EndDateTime : null,
                        ActivationDateTime: eventDetail.ActivationDateTime != null ? eventDetail.ActivationDateTime : null,
                        VenueId: eventDetail.VenueId,
                        VenueName: eventDetail.VenueDisplayName,
                        VenueSectionId: eventDetail.VenueSections[0].VenueSectionId,
                        VenueSectionName: eventDetail.VenueSections[0].VenueSectionDisplayName,
                        IsReserved: eventDetail.VenueSections[0].IsReserved,
                        AllocationId: eventDetail.VenueSections[0].Allocations[0].AllocationId,
                        Quantity: eventDetail.VenueSections[0].Allocations[0].Tickets[0].MaxQuantity,
                        TicketId: eventDetail.VenueSections[0].Allocations[0].Tickets[0].TicketId,
                        TicketName: eventDetail.VenueSections[0].Allocations[0].Tickets[0].TicketDisplayName,
                        TicketPrice: eventDetail.VenueSections[0].Allocations[0].Tickets[0].TicketPrice,
                        TicketOriginalPrice: eventDetail.VenueSections[0].Allocations[0].Tickets[0]
                            .OriginalTicketPrice,
                        TicketTaxTypeId: eventDetail.VenueSections[0].Allocations[0].Tickets[0].TaxTypeId,
                        TicketMembershipProgramId: eventDetail.VenueSections[0].Allocations[0].Tickets[0]
                            .MembershipProgramId,
                        IsPurchase: addOn.IncludeWithPurchase,
                        AddOnId: addOn.AddOnId,
                        ParentItemId: parentItemId,
                        IsSoldOut: eventDetail.IsSoldOut
                    };
                    result.push(ticketAddOn);
                }
            } else if (addOn.ItemTypeId === 2) {
                //package
                for (var i = 0; i < quantity; i++) {
                    var packageDetail = JSON.parse(JSON.stringify(response.data));
                    var packageAddOn = {
                        PackageId: packageDetail.TicketPackageId,
                        PackageName: packageDetail.Name,
                        Description: packageDetail.Description,
                        AllocationId: packageDetail.AllocationId,
                        ClientId: clientId,
                        ClientName: clientName,
                        Price: packageDetail.Price,
                        OriginalPrice: packageDetail.OriginalPrice,
                        Quantity: packageDetail.MaxQuantity,
                        TaxTypeId: packageDetail.TaxTypeId,
                        IsPurchase: addOn.IncludeWithPurchase,
                        AddOnId: addOn.AddOnId,
                        ParentItemId: parentItemId,
                    };
                    result.push(packageAddOn);
                }
            } else if (addOn.ItemTypeId === 3) {
                //pass
                for (var i = 0; i < quantity; i++) {
                    var passDetail = JSON.parse(JSON.stringify(response.data));
                    var passAddOn = {
                        PassId: passDetail.TicketPassId,
                        PassName: passDetail.Name,
                        Description: passDetail.Description,
                        AllocationId: passDetail.AllocationId,
                        ClientId: clientId,
                        ClientName: clientName,
                        Price: passDetail.Price,
                        OriginalPrice: passDetail.OriginalPrice,
                        Quantity: passDetail.MaxQuantity,
                        TaxTypeId: passDetail.TaxTypeId,
                        IsPurchase: addOn.IncludeWithPurchase,
                        ParentItemId: parentItemId,
                        AddOnId: addOn.AddOnId,
                    };
                    result.push(passAddOn);
                }
            } else if (addOn.ItemTypeId === 5) {
                //membership
                //only 1 membership per user so no loop here
                var membershipDetail = JSON.parse(JSON.stringify(response.data));
                var membershipAddOn = {
                    MembershipProgramId: membershipDetail.MembershipProgramId,
                    MembershipName: membershipDetail.Name,
                    Description: membershipDetail.Description,
                    ClientId: clientId,
                    ClientName: clientName,
                    AddOnPrice: membershipDetail.Price,
                    IsRenewal: false,
                    IsAddOn: true,
                    IsPurchase: addOn.IncludeWithPurchase,
                    ParentItemId: parentItemId,
                    AddOnId: addOn.AddOnId,
                };
                result.push(membershipAddOn);
            } else if (addOn.ItemTypeId === 7) {
                //extra
                for (var i = 0; i < quantity; i++) {
                    var extraDetail = JSON.parse(JSON.stringify(response.data));
                    var extraAddOn = {
                        ExtraId: extraDetail.ExtraId,
                        ExtraName: extraDetail.Name,
                        Description: extraDetail.Description,
                        AllocationId: extraDetail.AllocationId,
                        ClientId: clientId,
                        ClientName: clientName,
                        Price: extraDetail.Price,
                        OriginalPrice: extraDetail.OriginalPrice,
                        Quantity: extraDetail.MaxQuantity,
                        TaxTypeId: extraDetail.TaxTypeId,
                        IsPurchase: addOn.IncludeWithPurchase,
                        ParentItemId: parentItemId,
                        AddOnId: addOn.AddOnId,
                    };
                    result.push(extraAddOn);
                }
            }
            
            resolve(result);
        });        
    });
};

function setAddOn(addOn, eligibleAddOns, parentItemId, quantity, clientId, clientName, antiForgeryToken) {
    getAddOnDetail(addOn, parentItemId, quantity, clientId, clientName, antiForgeryToken).then(function (response) {
        if (response == null || response.length <= 0)
            return;
        switch (addOn.ItemTypeId) {
            case 1:
                response.forEach(function (r) {
                    eligibleAddOns.eventAddOns.push(r);
                });
                break;
            case 2:
                response.forEach(function (r) {
                    eligibleAddOns.packageAddOns.push(r);
                });
                break;
            case 3:
                response.forEach(function (r) {
                    eligibleAddOns.passAddOns.push(r);
                });
                break;
            case 5:
                response.forEach(function (r) {
                    eligibleAddOns.membershipAddOns.push(r);
                });
                break;
            case 7:
                response.forEach(function (r) {
                    eligibleAddOns.extraAddOns.push(r);
                });
                break;
        }
    });
};

function setAddOns(addOns, eligibleAddOns, index) {
        if (addOns == null || addOns.length <= 0 || index >= addOns.length)
            return;
        getAddOnDetail(addOns[index].addOn, addOns[index].parentItemId, addOns[index].quantity, addOns[index].clientId, addOns[index].clientName, addOns[index].antiForgeryToken).then(function (response) {
            if (response == null || response.length <= 0)
                return;

            switch (addOns[index].addOn.ItemTypeId) {
                case 1:
                    response.forEach(function (r) {
                        eligibleAddOns.eventAddOns.push(r);
                    });
                    break;
                case 2:
                    response.forEach(function (r) {
                        eligibleAddOns.packageAddOns.push(r);
                    });
                    break;
                case 3:
                    response.forEach(function (r) {
                        eligibleAddOns.passAddOns.push(r);
                    });
                    break;
                case 5:
                    response.forEach(function (r) {
                        eligibleAddOns.membershipAddOns.push(r);
                    });
                    break;
                case 7:
                    response.forEach(function (r) {
                        eligibleAddOns.extraAddOns.push(r);
                    });
                    break;
            }
            if (index + 1 < addOns.length)
                setAddOns(addOns, eligibleAddOns, index + 1);
        });
};

function getAddOnLockRequest(eligibleAddOns, parentItemId) {
    var request = {
        EventAddOns: [],
        PackageAddOns: [],
        PassAddOns: [],
        ExtraAddOns: [],
        MembershipAddOns: []
    };
    eligibleAddOns.eventAddOns.forEach(function (e) {
        if (e.IsPurchase && e.ParentItemId === parentItemId) {
            request.EventAddOns.push(e);
        }
    });
    eligibleAddOns.packageAddOns.forEach(function (p) {
        if (p.IsPurchase && p.ParentItemId === parentItemId) {
            request.PackageAddOns.push(p);
        }
    });
    eligibleAddOns.passAddOns.forEach(function (p) {
        if (p.IsPurchase && p.ParentItemId === parentItemId) {
            request.PassAddOns.push(p);
        }
    });
    eligibleAddOns.extraAddOns.forEach(function (p) {
        if (p.IsPurchase && p.ParentItemId === parentItemId) {
            request.ExtraAddOns.push(p);
        }
    });
    eligibleAddOns.membershipAddOns.forEach(function (p) {
        if (p.IsPurchase && p.ParentItemId === parentItemId) {
            request.MembershipAddOns.push(p);
        }
    });

    return request;
}